import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import Img from '../../image/logo.png'
export default function Navbar() {
    const history = useHistory()
    const logout = () => {
        localStorage.removeItem('user')
        localStorage.removeItem('loggedin')
        localStorage.removeItem('useremail')
        localStorage.removeItem('token')
        history.push('/')
    }
    return (
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">

            <Link className="navbar-brand ps-3" to="/dashboard"><img src={Img} className='rounded-circle'/> Roof Inspection Pro</Link>

            <button className="btn btn-sm order-1 order-lg-0 me-4 me-lg-0 " id="sidebarToggle" href="#!"><i className="fas fa-bars"></i> ☰ </button>

            <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                <div className="input-group">
                    <input className="form-control" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                    <button className="btn btn-primary" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button>
                </div>
            </form>

            <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">

                <div className="sb-sidenav-menu-heading">Core</div>
                <div style={{ cursor: "pointer" }} className="nav-link" onClick={logout}>
                    <div className="sb-nav-link-icon "><i className="fas fa-tachometer-alt"></i></div>
                    Logout
                </div>
            </ul>
        </nav>
    )
}
