import React, { useState, useEffect } from "react";
import axios from "axios";
import crypto from "crypto";
import { useHistory, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../loading/Loading";
import Button from "@material-ui/core/Button";
export default function Editreport() {
  let userid = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  const isAuthenticated = localStorage.getItem("loggedin");
  const history = useHistory();
  const data = history.location.state;
  let [filledreport, setfilledreport] = useState([]);
  const [showloading, setLoading] = useState(true);
  const [report, setreport] = useState({
    registrationId: "",
    companyname: "",
    fullname: "",
    referencenumber: "",
    claimnumber: "",
    phonenumber: "",
    address: "",
    emailaddress: "",
    other: "",
    dateofevent: "",
    eventrelatedopening: "",
    dateofinspection: "",
    timeofinspection: "",
    addressofinspection: "",
    categoryname: "",
    useranswer: [],
  });
  let [useranswer, setuseranswer] = useState(report.useranswer);
  let [questions, setquestions] = useState([]);
  const [time, setTime] = useState("00:00");
  let [status, setstatus] = useState(null);
  const [eventopening, seteventopening] = useState(false);
  const [coverphoto, setcoverphoto] = useState("");
  const [coverimage, setcoverimage] = useState("");
  let [coverquestion, setcoverquestion] = useState("");
  const getTime = (time) => {
    if (time && time.search("AM") !== -1) {
      setTime(time.split("AM")[0].trim());
    } else {
      setTime(
        time
          ? String(parseInt(time.split(":")[0]) + 12)
              .concat(":" + time.split(":")[1].split("PM")[0])
              .trim()
          : ""
      );
    }
  };
  delete report.useranswer;
  const childdata = (data) => {
    questions.map((que) => {
      if (
        (que.is_complusory_question &&
          data.question_number == que.question_number) ||
        data.optionalanswer ||
        data.furthercomment ||
        data.question_option.length > 0 ||
        data.question_image.length > 0
      ) {
        filledreport[data.index] = data;
      }
    });
    useranswer[data.index] = data;
  };
  //get data for edit from database
  const getEditdata = () => {
    data["userid"] = userid;
    data["token"] = token;
    if (history.location.state) {
      axios
        .post(
          `${process.env.REACT_APP_API_URI}/user-report/edit-user-report`,
          data
        )
        .then((res) => {
          if (res.data.statuscode == 200) {
            let filter = res.data.userreport[0].useranswer.filter(
              (question, index) => {
                question.question_image.filter((image) => {
                  if (image.iscoverphoto) {
                    coverquestion = question;
                    setcoverquestion(question);
                  }
                });
                return question.question.search("(cover photo)") == -1;
              }
            );
            setuseranswer(filter);

            setreport(res.data.userreport[0]);
            setLoading(false);
            setstatus(res.data.userreport[0].iscompleted ? true : false);
            getTime(res.data.userreport[0].timeofinspection);
            setcoverphoto(
              coverquestion.question_image
                ? coverquestion.question_image[0].imagefile
                : ""
            );
            if (res.data.userreport[0].eventrelatedopening == "Yes") {
              seteventopening(true);
            } else {
              seteventopening(false);
            }
          }
        });
    }
  };
  // get all questions
  const getallquestions = () => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/user-question-list/question-list`)
      .then((res) => {
        if (res.data.statuscode == 200) {
          setquestions(res.data.questions);
        }
      });
  };
  //onChange for main object
  const handlereport = (e) => {
    setreport({ ...report, [e.target.name]: e.target.value });
  };
  //convert base64

  const handleCoverphoto = async (e) => {
    if (e.target.value) {
      const file = e.target.files[0];
      setcoverphoto(URL.createObjectURL(file));
      setcoverimage(file);
    }
  };
  const reset = () => {
    getEditdata();
    setLoading(true);
  };
  const removecover = () => {
    setcoverphoto("");
    setcoverquestion({
      question_id: (
        m = Math,
        d = Date,
        h = 16,
        s = (s) => m.floor(s).toString(h)
      ) =>
        s(d.now() / 1000) +
        " ".repeat(h).replace(/./g, () => s(m.random() * h)),
      question: "Picture of property or roof(cover photo)",
      question_option: [],
      question_image: [],
      categoryname: "",
      add_photo: true,
      actionbar_title: "Optional Question",
      categoryid: "",
      furthercomment: "",
      optionalanswer: "",
    });
  };
  //submit updated data
  const updatedata = (e) => {
    e.preventDefault();
    let Formdata = new FormData();
    if (!report.fullname) {
      toast("fullname is required field");
      return 0;
    } else if (!report.address) {
      toast("address is required field");
      return 0;
    }else if (!report.addressofinspection) {
      toast("address of inspection is required field");
      return 0;
    }else if (!report.dateofinspection) {
      toast("date of inspection is required field");
      return 0;
    }else if (!time) {
      toast("time of inspection is required field");
      return 0;
    }
    if (coverimage) {
      var updatedcoverquestion = {
        question_id: "612f428278779f0e68fb54f0",
        question: "Picture of property or roof(cover photo)",
        question_option: [],
        add_photo: true,
        actionbar_title: "Optional Question",
        categoryname: null,
        categoryid: null,
        furthercomment: null,
        optionalanswer: "",
      };
      Formdata.append("cover_image", coverimage);
    } else {
      if (coverquestion.question_image) {
        if (coverquestion.question_image[0]) {
          var updatedcoverquestion = {
            question_id: "612f428278779f0e68fb54f0",
            question: "Picture of property or roof(cover photo)",
            question_option: [],
            add_photo: true,
            actionbar_title: "Optional Question",
            question_image: [
              {
                imagefile: coverquestion.question_image[0].imagefile,
                imagename: coverquestion.question_image[0].imagename,
                extension: "",
                iscoverphoto: true,
              },
            ],
            categoryname: null,
            categoryid: null,
            furthercomment: null,
            optionalanswer: "",
          };
        } else {
          var updatedcoverquestion = {
            question_id: "612f428278779f0e68fb54f0",
            question: "Picture of property or roof(cover photo)",
            question_option: [],
            add_photo: true,
            actionbar_title: "Optional Question",
            question_image: [],
            categoryname: null,
            categoryid: null,
            furthercomment: null,
            optionalanswer: "",
          };
        }
      } else {
        var updatedcoverquestion = {
          question_id: "612f428278779f0e68fb54f0",
          question: "Picture of property or roof(cover photo)",
          add_photo: true,
          actionbar_title: "Optional Question",
          question_option: [],
          question_image: [],
          categoryname: null,
          categoryid: null,
          furthercomment: null,
          optionalanswer: "",
        };
      }
    }
    let submit = true;
    let imagerequired = true;
    var terminate = false;
    filledreport.map((question, number) => {
      if (!terminate) {
        for (let x = 0; x < question.question_image.length; x++) {
          if (
            (question.question_image[x].imagefile == "" ||
              question.question_image[x].imagefile == " ") &&
            !question.question_image[x].iscoverphoto
          ) {
            toast(
              "please select image in question " + question.question_number
            );
            submit = false;
            imagerequired = false;
            terminate = true;
            break;
          }

          if (
            (question.question_image[x].imagefile != "" ||
              question.question_image[x].imagefile != " ") &&
            question.question_option.length == 0
          ) {
            // console.log(question.question_number);
            submit = false;
            toast("Please answer the question " + question.question_number);
            terminate = true;
            break;
          }
        }
      }
    });

    filledreport.map((question) => {
      if (question.question_image) {
        if (question.question_image.length > 0) {
          question.question_image.map((image, i) => {
            if (image.src) {
              Formdata.append(
                `report_image-${question.question_id}`,
                image.src
              );
              if (question.question_images == []) {
                question.question_image = [];
              }
            }
          });
        }
      }
    });

    let filled = [];
    for (let i = 0; i < filledreport.length; i++) {
      if (filledreport[i]) {
        filled.push(filledreport[i]);
        if (
          (filledreport[i].question_option[0] == undefined ||
            filledreport[i].question_option.option == "") &&
          filledreport.is_complusory_question
        ) {
          toast("please fill all complusory question");
          return false;
        }
        if (
          filledreport[i].question_number >= 22 &&
          filledreport[i].question_number <= 49 &&
          (filledreport[i].question_option.length > 0 ||
            filledreport[i].question_image.length > 0 ||
            filledreport[i].optionalanswer != "") &&
          (filledreport[i].categoryname == null ||
            filledreport[i].categoryname == "")
        ) {
          toast(
            "please select category for question nulmer " +
              filledreport[i].question_number
          );
          return false;
        }
      }
    }
    useranswer = [updatedcoverquestion, ...filled];
    report["lastupdateddate"] = new Date();
    report["useranswer"] = [...useranswer];

    if (submit) {
      if (status) {
        report["isinprogress"] = false;
        report["iscompleted"] = true;
        report["isarchive"] = false;
      } else {
        report["isinprogress"] = true;
        report["iscompleted"] = false;
        report["isarchive"] = false;
      }
      Formdata.append("report", JSON.stringify([report]));
      // console.log("submit", report);
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_URI}/user-report/update-report?token=${token}`,
          Formdata
        )
        .then((res) => {
          //   console.log("submited res", res.data.userreport);
          if (res.data.statuscode == 200) {
            setLoading(false);
            if (res.data.status == "success") {
              toast("Report updated successfully");
              history.push("/reportlist");
            }
          }
        });
    } else {
      if (imagerequired) {
        // toast("fill all required fields");
      }
    }
  };

  useEffect(() => {
    getEditdata();
    getallquestions();
  }, []);
  return isAuthenticated ? (
    showloading ? (
      <div className="container  m-0 p-0">
        <Loading />
      </div>
    ) : history.location.state != undefined ? (
      <div className="col-md-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Edit Report</h4>
            <form className="forms-sample">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">
                      Full Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName1"
                        placeholder="Full Name"
                        name="fullname"
                        value={report.fullname ? report.fullname : ""}
                        onChange={handlereport}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">Company Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputcompanyname"
                        placeholder="Company Name"
                        name="companyname"
                        value={report.companyname ? report.companyname : ""}
                        onChange={handlereport}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">Email Address</label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail3"
                        placeholder="Email Address"
                        name="emailaddress"
                        value={report.emailaddress ? report.emailaddress : ""}
                        onChange={handlereport}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">Phone Number</label>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputPhone"
                        placeholder="Phone Number"
                        name="phonenumber"
                        value={report.phonenumber ? report.phonenumber : ""}
                        onChange={handlereport}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">
                      Address<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        placeholder="Address"
                        id="exampleTextarea1"
                        rows="4"
                        style={{"resize": "none"}}
                        value={report.address ? report.address : ""}
                        name="address"
                        required
                        onChange={handlereport}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">
                      Address of inspection<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="col-sm-9">
                      <textarea
                      required
                        className="form-control"
                        id="exampleTextarea1addressins"
                        rows="4"
                        style={{"resize": "none"}}
                        placeholder="Address of inspection"
                        name="addressofinspection"
                        value={
                          report.addressofinspection
                            ? report.addressofinspection
                            : ""
                        }
                        onChange={handlereport}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">
                      Reference Number
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputreferencenumber"
                        placeholder="Reference Number"
                        name="referencenumber"
                        value={report.referencenumber}
                        onChange={handlereport}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">Claim Number</label>
                    <div className="col-sm-9">
                      <input
                        className="form-control"
                        placeholder="Claim Number"
                        id="exampleother"
                        value={report.claimnumber}
                        name="claimnumber"
                        onChange={handlereport}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">Date of event</label>
                    <div className="col-sm-9">
                      <input
                        type="date"
                        style={{ border: "1px solid rgb(222,226,230)" }}
                        onChange={handlereport}
                        value={
                          report.dateofevent
                            ? new Date(report.dateofevent)
                                .toLocaleDateString("pt-br")
                                .split("/")
                                .reverse()
                                .join("-")
                            : ""
                        }
                        name="dateofevent"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">
                      Event related opening
                    </label>
                    <div className="col-sm-9">
                      <input
                        style={{ border: "1px solid rgb(222,226,230)" }}
                        type="checkbox"
                        name="eventrelatedopening"
                        checked={eventopening}
                        onChange={() => seteventopening(!eventopening)}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">
                      Date of inspection<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="date"
                        style={{ border: "1px solid rgb(222,226,230)" }}
                        onChange={handlereport}
                        value={
                          report.dateofinspection
                            ? new Date(report.dateofinspection)
                                .toLocaleDateString("pt-br")
                                .split("/")
                                .reverse()
                                .join("-")
                            : ""
                        }
                        name="dateofinspection"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">
                      Time of inspection<span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        style={{ border: "1px solid rgb(222,226,230)" }}
                        type="time"
                        value={time ? time : ""}
                        onChange={(e) => setTime(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="form-group">
                <label htmlFor="exampleTextarea2">Other</label>
                <input
                  className="form-control"
                  placeholder="Other"
                  id="exampleTextarea2"
                  rows="4"
                  value={report.other ? report.other : ""}
                  name="other"
                  onChange={handlereport}
                ></input>
              </div>
              <hr style={{ borderTop: "2px solid red" }} />
              <div style={{ fontSize: "20px" }}>
                Picture of property or roof (cover
                photo):&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                {coverphoto && (
                  <img
                    style={{
                      display: "inline-block",
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                    }}
                    src={coverphoto}
                    alt={""}
                  />
                )}
              </div>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="contained-button-file"
                onChange={handleCoverphoto}
                name="coverphoto"
              />
              <br />
              <br />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  Upload
                </Button>
              </label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <svg
                width="40"
                height="20"
                fill="currentColor"
                className="bi bi-trash-fill"
                viewBox="0 0 20 16"
                onClick={removecover}
                style={{ cursor: "pointer" }}
              >
                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
              </svg>
              <hr style={{ borderTop: "2px solid black" }} />
              {/* {useranswer.map((question, i) =>
                question.question.search("(cover photo)") == -1 ? (
                  <div key={i}>
                    <Editquestion
                      question={question}
                      index={i}
                      arraylen={useranswer.length}
                      childdata={childdata}
                    />
                    <hr style={{ borderTop: "2px solid black" }} />
                  </div>
                ) : (
                  ""
                )
              )} */}
              {questions.map((question, i) => {
                var que = [];
                let match = false;
                for (const item of useranswer) {
                  if (item) {
                    if (item.question == question.question) {
                      match = true;
                      delete item["question_id"];
                      item["question_id"] = question._id;
                      item["question_number"] = question.question_number;
                      item["add_photo"] = question.add_photo;
                      item["actionbar_title"] = question.actionbar_title;
                      item["is_complusory_question"] =
                        question.is_complusory_question;
                      que.push(
                        <div key={question._id}>
                          <Editquestion
                            reportQuestions={useranswer}
                            question={item}
                            index={question.question_number}
                            arraylen={useranswer.length}
                            childdata={childdata}
                          />
                          <hr style={{ borderTop: "2px solid black" }} />
                        </div>
                      );
                      break;
                    }
                  }
                }
                if (!match) {
                  delete question["question_option"];
                  delete question["question_id"];
                  question["question_option"] = [];
                  question["question_id"] = question._id;
                  que.push(
                    <div key={question._id}>
                      <Editquestion
                        reportQuestions={useranswer}
                        question={question}
                        index={question.question_number}
                        arraylen={useranswer.length}
                        childdata={childdata}
                      />
                      <hr style={{ borderTop: "2px solid black" }} />
                    </div>
                  );
                }

                return que[0];
              })}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={() => setstatus(!status)}
                  name="status"
                  defaultChecked={status}
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  I have completed this report
                </label>
              </div>
              <br />
              <button
                type="submit"
                className="btn btn-primary me-2"
                onClick={updatedata}
              >
                Update
              </button>
              <div className="btn btn-light" onClick={reset}>
                Reset
              </div>
            </form>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={2100}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={"dark"}
        />
      </div>
    ) : (
      <Redirect to="/dashboard" />
    )
  ) : (
    <Redirect to="/" />
  );
}

export function Editquestion(props) {
  const history = useHistory();
  let [option, setOption] = useState([]);
  let [alloptions, setallOptions] = useState([]);
  let [img, setimg] = useState([]);
  const [question, setquestion] = useState({
    question_id: "",
    question_number: "",
    question: "",
    add_photo: false,
    actionbar_title: "",
    lastupdateddate: "",
    question_option: [],
    question_image: [],
    categoryname: "",
    categoryid: "",
    furthercomment: "",
    optionalanswer: "",
  });
  const [categories, setcategories] = useState([]);
  const [imghandler, setimagehandler] = useState([]);
  const [addphoto, setaddphoto] = useState(false);
  const [count, setcount] = useState(0);
  const geteditquestion = (question) => {
    setquestion(question);
    if (question.question_option != undefined) {
      setOption(question.question_option);
      option = question.question_option;
    }
    if (question.question_image != undefined) {
      setimg(question.question_image);
      let changeimg = [];
      for (let i = 0; i < question.question_image.length; i++) {
        changeimg.push(false);
      }
      setimagehandler(changeimg);
      img = question.question_image;
    }
  };
  const getcategory = () => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/category/category-list`)
      .then((res) => {
        if (res.data.statuscode == 200) {
          setcategories(res.data.category);
        }
      });
  };
  const getalloptions = (question) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/user-question-list/get-question-options`,
        { id: question }
      )
      .then((res) => {
        if (res.data.statuscode == 200) {
          if (res.data.data2[0]) {
            if (res.data.data2[0].options) {
              if (res.data.data2[0].options.length > 0) {
                setallOptions(res.data.data2[0].options);
              }
            }
            setaddphoto(res.data.data2[0].photo);
          }
        }
      });
  };
  useEffect(() => {
    geteditquestion(props.question);
    setquestion(props.question);
    getcategory();
    getalloptions(props.question.question);
  }, []);

  const all_data = () => {
    let arraynumber = {
      ...question,
      question_image: img,
      index: props.index,
    };
    props.childdata(arraynumber);
  };

  //for handling static inputs
  const handleinp = (e) => {
    if (e.target.name == "question_option") {
      if (e.target.value) {
        let newoption = alloptions[e.target.value];
        setquestion({ ...question, [e.target.name]: [newoption] });
      } else {
        setquestion({ ...question, [e.target.name]: [] });
      }
    } else if (e.target.name == "categoryname" && e.target.value) {
      categories.map((ele) => {
        if (e.target.value == ele.categoryname) {
          setquestion({
            ...question,
            categoryid: ele._id,
            categoryname: e.target.value,
          });
        }
      });
    } else {
      setquestion({ ...question, [e.target.name]: e.target.value });
    }
  };
  //Dynamic images handle

  //add new image
  const addimgClick = () => {
    // add img
    setcount(count + 1);
    if (img) {
      setimg([
        ...img,
        { imagename: "", extension: "", imagefile: "", iscoverphoto: false },
      ]);
    } else {
      setimg([
        { imagename: "", extension: "", imagefile: "", iscoverphoto: false },
      ]);
    }
  };

  //onchange function for image field
  const handleimg = async (e, index) => {
    const file = e.target.files[0];
    const list = [...img];
    const changed = [...imghandler];
    changed[index] = true;
    setimagehandler(changed);
    list[index]["imagename"] = file.name.split(".")[0];
    list[index]["imagefile"] = URL.createObjectURL(file);
    list[index]["src"] = file;
    setimg(list);
  };
  //remove image field
  const removeimgClick = (index) => {
    setcount(count - 1);
    const list = [...img];
    list.splice(index, 1);
    setimg(list);
  };

  //generate image input field
  const createimgUI = () =>
    img &&
    img.map((image, j) => (
      <div style={{ width: "205px", marginLeft: "1%" }} key={j}>
        <div className="form-group ">
          <label htmlFor={`img${j}`} className="col-sm-3 col-form-label">
            Image{j + 1}
          </label>
          <div className="col-sm-9 d-flex justify-content-between">
            {imghandler[j] ? (
              <p key={j}>
                {image && image.imagefile ? (
                  <img
                    src={`${image.imagefile}`}
                    width={100}
                    height={100}
                    alt=" "
                  />
                ) : (
                  ""
                )}{" "}
              </p>
            ) : (
              <p key={j}>
                {image && image.imagefile ? (
                  <img
                    src={`${image.imagefile}`}
                    width={100}
                    height={100}
                    alt=" "
                  />
                ) : (
                  ""
                )}{" "}
              </p>
            )}
            {addphoto ? (
              <div>
                <svg
                  width="40"
                  height="20"
                  fill="currentColor"
                  className="bi bi-trash-fill"
                  viewBox="0 0 20 16"
                  onClick={() => removeimgClick(j)}
                  style={{ cursor: "pointer" }}
                >
                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                </svg>
                {/* <p  className="btn btn-danger btn-sm" onClick={() => removeimgClick(j)}>X</p> */}
              </div>
            ) : (
              ""
            )}
          </div>
          {addphoto ? (
            <div className="form-group">
              <input
                type="file"
                accept="image/*"
                // style={{ display: 'none' }}
                id={`contained-button-file${j}`}
                onChange={(e) => handleimg(e, j)}
                name="profileimage"
                required
              />
              {/* <label htmlFor={`contained-button-file${j}`}>
                                <Button variant="contained" color="primary" component="span">
                                    Upload
                                </Button>
                            </label> */}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    ));

  return (
    <div className="col-md-12 grid-margin ">
      <h4>
        Question {question.question_number}{" "}
        {question.is_complusory_question && (
          <span style={{ color: "red" }}>*</span>
        )}
      </h4>
      <div className="forms-sample">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group row">
              <label className="col-sm-3 form-label">Question</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputMobile"
                  placeholder="Question"
                  name="question"
                  readOnly
                  defaultValue={question.question ? question.question : ""}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group row">
              <label className="col-sm-3 form-label">Further comment</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail2"
                  placeholder="Further comment"
                  name="furthercomment"
                  readOnly={alloptions.length == 0 ? false : true}
                  onChange={handleinp}
                  value={question.furthercomment ? question.furthercomment : ""}
                />
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-md-6">
            <div className="form-group row">
              <label className="col-sm-3 form-label">Category name</label>
              <div className="col-sm-9">
                <select
                  className="form-select w-75"
                  id="exampleInputcategory"
                  aria-label="Default select example"
                  disabled={
                    !(
                      22 <= question.question_number &&
                      question.question_number <= 49
                    )
                  }
                  value={question.categoryname ? question.categoryname : ""}
                  name="categoryname"
                  onChange={handleinp}
                >
                  {!question.categoryname && <option value="">Select</option>}
                  <option value="General">General</option>
                  <option value="Event">Event</option>
                  <option value="Maintenance">Maintenance</option>
                  <option value="Defect">Defect</option>
                  <option value="Safety">Safety</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {alloptions.length > 0 && (
              <div className="form-group row">
                <label className="col-sm-3 form-label">Answer</label>
                <div className="col-sm-9">
                  {
                    <select
                      className="form-select w-75"
                      id="exampleInputcategory"
                      aria-label="Default select example"
                      value={
                        question.question_option.length > 0 &&
                        question.question_option[0]
                          ? question.question_option[0].option_id - 1
                          : ""
                      }
                      name="question_option"
                      onChange={handleinp}
                    >
                      {question.question_option.length == 0 && (
                        <option value="">Select</option>
                      )}
                      {alloptions &&
                        alloptions.map((ele, i) => (
                          <option key={i} value={i}>
                            {ele.option}
                          </option>
                        ))}
                    </select>
                  }
                </div>
              </div>
            )}
          </div>
        </div>
        <br />

        <div className="form-group">
          <label
            htmlFor="exampleInputUsername2"
            className="col-sm-3 form-label"
          >
            Optional answer
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              id="exampleInputUsername2"
              placeholder="Optional answer"
              name="optionalanswer"
              readOnly={
                !(
                  22 <= question.question_number &&
                  question.question_number <= 49
                )
              }
              onChange={handleinp}
              value={question.optionalanswer ? question.optionalanswer : ""}
            />
          </div>
        </div>
        <br />
        {/* <h5>Images</h5> */}
        <div className="d-flex align-content-start flex-wrap">
          {createimgUI()}
        </div>
        <br />
        <div className="form-group">
          <div className="col-sm-9">
            {addphoto && count < 4 ? (
              <button
                type="button"
                className=" btn btn-success"
                id="addAnswerid"
                onClick={addimgClick}
              >
                Add Image
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        {all_data()}
      </div>
    </div>
  );
}
