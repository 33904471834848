import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Login from './components/auth/Login'
import Signup from './components/auth/Signup'
import Profile from './components/sidecontent/Profile';
import Reportlist from './components/sidecontent/report/Reportlist';
import Addreport from './components/sidecontent/report/Addreport';
import Editreport from './components/sidecontent/report/Editreport';
import UnSubscribe from './components/sidecontent/UnSubscribe';
// import Subscribe from './components/auth/Subscription'
import Support from './components/sidecontent/Support';
import Main from './components/main/Main';
import Dashboard from './components/sidecontent/Dashboard';
import ChangePass from './components/sidecontent/ChangePassword';
import Forgetpass from './components/auth/Forgetpass';
import ViewReport from './components/sidecontent/report/ViewReport';
function App() {
  const isAuthenticated = localStorage.getItem("loggedin");
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/dashboard">
            <Main component={<Dashboard />} />
          </Route>
          <Route exact path="/viewreport">
            <Main component={<ViewReport />} />
          </Route>
          <Route exact path="/changepassword">
            <Main component={<ChangePass />} />
          </Route>
          <Route exact path="/profile">
            <Main component={<Profile />} />
          </Route>
          <Route exact path="/reportlist">
            <Main component={<Reportlist />} />
          </Route>
          <Route exact path="/addreport">
            <Main component={<Addreport />} />
          </Route>
          <Route exact path="/editreport">
            <Main component={<Editreport />} />
          </Route>
          <Route exact path="/cancle-subscription">
            <Main component={<UnSubscribe />} />
          </Route>
          {/* <Route exact path="/subscription">
            <Subscribe />
          </Route> */}
          <Route exact path="/forget-password">
            <Forgetpass />
          </Route>
          <Route exact path="/support">
            <Main component={<Support />} />
          </Route>
          <Route exact path="/signup" component={Signup} />

          <Route exact path="*">
            {isAuthenticated ?
              <Redirect to="/dashboard" />
              : <Redirect to="/" />}
          </Route>

        </Switch>

      </div>
    </Router>
  );
}

export default App;
