import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Loading from "../loading/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@material-ui/core/Button";
export default function Adduser() {
  const userid = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  const isAuthenticated = localStorage.getItem("loggedin");
  const [admin, setAdmin] = useState({
    companyname: "",
    fullname: "",
    emailaddress: "",
    registrationnumber: "",
    phonenumber: "",
    address: "",
    profileimage: "",
    country: "",
  });
  const [showloading, setLoading] = useState(true);
  const [emailerr, setemailerr] = useState(false);
  const [emailrequired, setemailrequired] = useState(false);
  const [phonerequired, setphonerequired] = useState(false);
  const [fullnamerequired, setfullnamerequired] = useState(false);
  const [submit, setsubmit] = useState(false);
  const [profilebase64, setbase64] = useState();
  const [imagename, setimagename] = useState();
  const [countries, setCountry] = useState();
  const [{ alt, src }, setImg] = useState({
    src: "images/user.png",
    alt: "Upload an Image",
  });
  // set base64
  const handleProfileChange = async (e) => {
    if (e.target.value) {
      const file = e.target.files[0];
      setImg({
        src: URL.createObjectURL(file),
        alt: file.name,
      });
      setimagename(file.name);
      setbase64(e.target.files[0]);
    }
  };
  const handleChange = (e) => {
    setsubmit(false);
    if (e.target.name == "emailaddress") {
      if (!validateEmail(e.target.value)) {
        setemailerr(true);
      } else {
        setemailerr(false);
      }
    }
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  };
  const isNumber = (evt) => {
    if ((evt.which < 48 || evt.which > 57) && evt.which > 31) {
      evt.preventDefault();
    }
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  //submit data
  function onSubmit(e) {
    e.preventDefault();
    // setsubmit(true);
    if (admin.emailaddress != "") {
      if (!validateEmail(admin.emailaddress)) {
        // setemailerr(true);
        toast("invalid email address");
        return 0;
      }
    } else {
      // setemailrequired(true);
      toast("emailaddress is required");
      return 0;
    }
    if (admin.phonenumber == "") {
      // setphonerequired(true);
      toast("phonenumber is required");
      return 0;
    }
    if (admin.fullname == "") {
      // setfullnamerequired(true);
      toast("fullname is required");
      return 0;
    }
    let formData = new FormData();
    formData.append("profileimage", imagename);
    formData.append("profile_image", profilebase64);
    // formData.append("imagefile", profilebase64);
    formData.append("companyname", admin.companyname);
    formData.append("fullname", admin.fullname);
    formData.append("emailaddress", admin.emailaddress);
    formData.append("registrationnumber", admin.registrationnumber);
    formData.append("phonenumber", admin.phonenumber);
    formData.append("address", admin.address);
    formData.append("country", admin.country);
    formData.append("token", token);
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/user-profile/update-profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data; ",
            "x-access-token": token,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        // setsubmit(false);
        // setemailerr(false);
        // setemailrequired(false);
        // setphonerequired(false);
        // setfullnamerequired(false);
        if (res.data.statuscode == 200) {
          setImg({
            src: "images/user.png",
            alt: "Upload an Image",
          });
          getuserprofile();
        }
        // history.push("/dashboard")
      });
  }
  //get countries
  const getcountry = () => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/country/getcountry`)
      .then((res) => {
        if (res.data.data2.length > 0) {
          setCountry(res.data.data2);
        }
      });
  };
  const getuserprofile = () => {
    axios
      .post(`${process.env.REACT_APP_API_URI}/user-profile/get-user-profile`, {
        token: token,
        userid: userid,
      })
      .then((res) => {
        if (res.data.statuscode == 200) {
          if (res.data.userprofile.profileimage) {
            setImg({
              src: res.data.userprofile.path,
              alt: res.data.userprofile.profileimage,
            });
          } else {
            setImg({
              src: "images/user.png",
              alt: "Upload an Image",
            });
          }
          setAdmin(res.data.userprofile);
          setimagename(res.data.userprofile.profileimage);
          setbase64(res.data.userprofile.imagefile);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    getcountry();
    getuserprofile();
  }, []);

  return isAuthenticated ? (
    showloading ? (
      <div className="container  m-0 p-0">
        <Loading />
      </div>
    ) : (
      <div className="col-md-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">User Profile</h4>
            <p className="card-description"></p>
            <form className="" onSubmit={onSubmit}>
              <div className="text-center ">
                <h4>Profile image</h4>
                {admin && (
                  <img
                    style={{
                      display: "inline-block",
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                    }}
                    src={src}
                    alt={alt}
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  onChange={handleProfileChange}
                  name="profileimage"
                />
                <br />
                <br />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Upload
                  </Button>
                </label>
              </div>
              <br />
              <div className="my-5">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInputtextcompany"
                    name="companyname"
                    value={admin.companyname ? admin.companyname : ""}
                    onChange={handleChange}
                    placeholder="companyname"
                  />
                  <label htmlFor="floatingInputtextcompany">Company name</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInputtextname"
                    name="fullname"
                    value={admin.fullname ? admin.fullname : ""}
                    onChange={handleChange}
                    placeholder="fullname"
                  />
                  <label htmlFor="floatingInputtextname">Full name</label>
                  {/* <span style={{ color: "red" }}>
                    {fullnamerequired && submit ? "fullname is required" : ""}
                  </span> */}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInputtextregnum"
                    name="referencenumber"
                    value={admin.referencenumber ? admin.referencenumber : ""}
                    onChange={handleChange}
                    placeholder="registrationnumber"
                  />
                  <label htmlFor="floatingInputtextregnum">
                    Registration number
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInputemail"
                    name="emailaddress"
                    value={admin.emailaddress ? admin.emailaddress : ""}
                    onChange={handleChange}
                    placeholder="email"
                  />
                  <label htmlFor="floatingInputemail">Email</label>
                  {/* <span style={{ color: "red" }}>
                    {emailerr ? "invalid email" : ""}
                  </span>
                  <span style={{ color: "red" }}>
                    {emailrequired && submit ? "email is required" : ""}
                  </span> */}
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInputclass"
                    name="phonenumber"
                    value={admin.phonenumber ? admin.phonenumber : ""}
                    maxLength={15}
                    onKeyDown={(e) => isNumber(e)}
                    minLength={5}
                    onChange={handleChange}
                    placeholder="Phone number"
                  />
                  <label htmlFor="floatingInputclass">Phone number</label>
                  {/* <span style={{ color: "red" }}>
                    {phonerequired && submit ? "phonenumber is required" : ""}
                  </span> */}
                </div>
                <div className="form-floating mb-3">
                  <textarea
                    type="text"
                    rows={4}
                    className="h-100 form-control"
                    id="floatingInputtext"
                    name="address"
                    value={admin.address ? admin.address : ""}
                    onChange={handleChange}
                    placeholder="Name"
                  />
                  <label htmlFor="floatingInputtext">Address</label>
                </div>
                <div className="form-floating mb-3">
                  <select
                    className="form-select"
                    onChange={handleChange}
                    name="country"
                    aria-label="Default select example"
                    value={admin.country ? admin.country : ""}
                  >
                    <option value="">Country</option>
                    {countries &&
                      countries.map((ele, i) => (
                        <option key={i} value={ele.name}>
                          {ele.name}
                        </option>
                      ))}
                  </select>
                  <label htmlFor="floatingSelectGrid">Country</label>
                </div>
              </div>
              <hr />
              <button type="submit" className="btn btn-primary me-2">
                Submit
              </button>
            </form>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={2100}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={"dark"}
        />
      </div>
    )
  ) : (
    <Redirect to="/" />
  );
}
// `${process.env.REACT_APP_API_URI}/UserPic/${admin.profileimage ? admin.profileimage : 'user.png'}`
