import React,{useEffect} from 'react'
import { Link } from "react-router-dom";
import axios from 'axios';
export default function Sidebar() {
    const user= localStorage.getItem("useremail");
   
    return (
        <div id="layoutSidenav_nav">
            <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                <div className="sb-sidenav-menu">
                    <div className="nav">
                        <div className="sb-sidenav-menu-heading">Module</div>
                        <Link className="nav-link" to="/dashboard">
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Dashboard
                        </Link>
                        {/* <div className="sb-sidenav-menu-heading">Profile</div> */}
                        <Link className="nav-link" to="/profile">
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Profile
                        </Link>
                        <Link className="nav-link" to="/changepassword">
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Change Password
                        </Link>
                        {/* <div className="sb-sidenav-menu-heading">Subscription</div> */}
                        <Link className="nav-link" to="/cancle-subscription">
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Change/Cancel Subscription 
                        </Link>
                        {/* <div className="sb-sidenav-menu-heading">Report</div> */}
                        <Link className="nav-link" to="/reportlist">
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Reports
                        </Link>
                        {/* <div className="sb-sidenav-menu-heading">Support</div> */}
                        <Link className="nav-link" to="/support">
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Support
                        </Link>
                    </div>
                </div>
                <div className="sb-sidenav-footer">
                    <div className="small">Logged in as:</div>
                    {user}
                </div>
            </nav>
        </div>

    )
}
