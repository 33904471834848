import React, { useState } from 'react'
import axios from 'axios'
import { Link, Redirect } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Img from '../../image/bg4.JPG'
export default function Forgetpass() {
    let [emailerr, setemailerr] = useState(false)
    const isAuthenticated = localStorage.getItem("loggedin");
    const [admin, setAdmin] = useState({ emailaddress: "" })

    const handleChange = (e) => {
        setAdmin({ ...admin, [e.target.name]: e.target.value })
    }
    const Submit = () => {
        setemailerr(false)
        if (admin.emailaddress !== "" && admin.emailaddress !== " ") {
            axios.post(`${process.env.REACT_APP_API_URI}/user-registration/user-forget-password`, admin).then((res) => {
                if (res.data.statuscode == 200) {
                    setAdmin({ emailaddress: "" })
                    toast(res.data.message)
                } else {
                    toast(res.data.message)
                }
            })
        } else {
            setemailerr(true)
        }

    }


    return (!isAuthenticated ?
        <div id="layoutAuthentication"  style={{backgroundImage:`url(`+Img+")",backgroundSize:"cover"}}>
            <div id="layoutAuthentication_content">
                <main >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="card  mt-5">
                                    <div className="card-header"><h3 className="text-center font-weight-light my-4">Reset Password</h3></div>
                                    <div className="card-body">
                                        <form>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" id="floatingInput" name="emailaddress" value={admin.emailaddress} onChange={handleChange} placeholder="Email" />
                                                <label htmlFor="floatingInput">Email-address</label>
                                                {emailerr ? <p style={{ color: 'red', fontSize: "14px" }}>Email is required</p> : ""}
                                            </div>


                                            <div className="btn btn-primary" onClick={Submit} >Forget Password</div>
                                        </form>
                                    </div>
                                    <div className="card-footer text-center py-3">
                                        <div className="small"><Link to="/">Back</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <div id="layoutAuthentication_footer" >
        <footer className="py-4 bg-dark mt-auto  fixed-bottom" >
          <div className="container-fluid px-4">
            <div className="d-flex align-items-center justify-content-between small">
              <div className="text-muted">
                Copyright &copy; support@roofinspectionpro.net
              </div>
              <div className="text-muted">
              From Australia&nbsp;&nbsp; :<a href="tel:1300 727 054" style={{ textDecoration:"none"}}>1300 727 054</a><br/>
              From Overseaas:<a href="tel:+61 478 566 998" style={{ textDecoration:"none"}}>+61 478 566 998</a>
               
              </div>
            </div>
          </div>
        </footer>
        <ToastContainer
          position="top-center"
          autoClose={2100}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={'dark'}
        />
      </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2100}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme={'dark'}
                />
            </div>

        </div> : <Redirect to='/dashboard' />
    )
}
