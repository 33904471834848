import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ChangePass() {
    const useremail = localStorage.getItem("useremail");
    const isAuthenticated = localStorage.getItem("loggedin");
    const token = localStorage.getItem("token");
    let [emailerr, setemailerr] = useState(false)
    let [passworderr, setpassworderr] = useState(false)
    let [passworderrcnf, setpassworderrcnf] = useState(false)
    const [admin, setAdmin] = useState({ currentpassword: "", newpassword: "", confirmpassword: "" })

    const handleChange = (e) => {
        setAdmin({ ...admin, [e.target.name]: e.target.value })
    }
    const Submit = () => {
        admin['emailaddress'] = useremail
        admin['token'] = token
        setemailerr(false)
        setpassworderr(false)
        setpassworderrcnf(false)
        if (admin.newpassword && admin.confirmpassword) {
            if (admin.newpassword === admin.confirmpassword) {
                axios.post(`${process.env.REACT_APP_API_URI}/user-registration/change-user-password`, admin).then((res) => {
                    toast(res.data.status)
                    if (res.data.statuscode == 200) {
                        setAdmin({ currentpassword: "", newpassword: "", confirmpassword: "" })
                    }
                })
            } else {
                setpassworderrcnf(true)
            }
        } else {
            if (admin.newpassword == "" || admin.newpassword == " ") {
                setemailerr(true)
            }
            if (admin.confirmpassword == "" || admin.confirmpassword == " ") {
                setpassworderr(true)
            }
        }

    }

    return (
        isAuthenticated ?
            <div id="layoutAuthentication">
                <div id="layoutAuthentication_content">
                    <main>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5">
                                    <div className="card  mt-5">
                                        <div className="card-header"><h3 className="text-center font-weight-light my-4">Reset Password</h3></div>
                                        <div className="card-body">
                                            <form>
                                                <div className="form-floating mb-3">
                                                    <input type="text" className="form-control" id="floatingInput" name="currentpassword" value={admin.currentpassword} onChange={handleChange} placeholder="Title" />
                                                    <label htmlFor="floatingInput">Current Password</label>
                                                    {emailerr ? <p style={{ color: 'red', fontSize: "14px" }}>Current Password is required</p> : ""}
                                                </div>
                                                <div className="form-floating mb-3">
                                                    <input type="text" className="form-control" id="floatingInput" name="newpassword" value={admin.newpassword} onChange={handleChange} placeholder="Title" />
                                                    <label htmlFor="floatingInput">New Password</label>
                                                    {emailerr ? <p style={{ color: 'red', fontSize: "14px" }}>New Password is required</p> : ""}
                                                </div>
                                                <div className="form-floating mb-3">
                                                    <input type="text" className="form-control" id="floatingPassword" name="confirmpassword" value={admin.confirmpassword} onChange={handleChange} placeholder="confirmpassword" />
                                                    <label htmlFor="floatingPassword">Confirm Password</label>
                                                    {passworderr ? <p style={{ color: 'red', fontSize: "14px" }}>Confirm Password is required</p> : ""}
                                                    {passworderrcnf ? <p style={{ color: 'red', fontSize: "14px" }}> The password and Confirm password do not match</p> : ""}
                                                </div>
                                                <div className="btn btn-primary" onClick={Submit} >Change Password</div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <ToastContainer
                        position="top-center"
                        autoClose={2100}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme={'dark'}
                    />
                </div>

            </div> : <Redirect to='/' />
    )
}

export default ChangePass