import React from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import { Redirect } from 'react-router-dom'
export default function Dashboard(props) {
    const isAuthenticated = localStorage.getItem("loggedin");
    const component = props.component
    return (isAuthenticated ?
        <div>
            <Navbar />
            <div id="layoutSidenav">
                <Sidebar />
                <div id="layoutSidenav_content">
                    {component}

                </div>
            </div>
        </div> : <Redirect to='/' />
    )
}
