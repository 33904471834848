import React, { useState, useEffect } from "react";
import axios from "axios";
import crypto from "crypto";
import { useHistory, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../loading/Loading";
import Button from "@material-ui/core/Button";
export default function Editreport() {
  let userid = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  const isAuthenticated = localStorage.getItem("loggedin");
  const history = useHistory();
  const data = history.location.state;
  const [showloading, setLoading] = useState(true);
  let [questions, setquestions] = useState([]);
  const [report, setreport] = useState({
    registrationId: "",
    companyname: "",
    fullname: "",
    referencenumber: "",
    phonenumber: "",
    address: "",
    emailaddress: "",
    other: "",
    dateofinspection: "",
    timeofinspection: "",
    addressofinspection: "",
    categoryname: "",
    dateofevent: "",
    eventrelatedopening: "",
    claimnumber: "",
    useranswer: [],
  });
  let [useranswer, setuseranswer] = useState([]);
  const [time, setTime] = useState("00:00");
  const [covername, setcovername] = useState("");
  const [coverphoto, setcoverphoto] = useState("");
  let [coverquestion, setcoverquestion] = useState("");
  const getTime = (time) => {
    if (time && time.search("AM") !== -1) {
      setTime(time.split("AM")[0].trim());
    } else {
      setTime(
        time
          ? String(parseInt(time.split(":")[0]) + 12)
              .concat(":" + time.split(":")[1].split("PM")[0])
              .trim()
          : ""
      );
    }
  };
  delete report.useranswer;
  const childdata = (data) => {
    useranswer[data.index] = data;
  };
  //get data for edit from database
  const getEditdata = () => {
    data["userid"] = userid;
    data["token"] = token;
    if (history.location.state) {
      axios
        .post(
          `${process.env.REACT_APP_API_URI}/user-report/edit-user-report`,
          data
        )
        .then((res) => {
          if (res.data.statuscode == 200) {
            setuseranswer(res.data.userreport[0].useranswer);
            let filter = res.data.userreport[0].useranswer.filter(
              (question, index) => {
                question.question_image.filter((image) => {
                  if (image.iscoverphoto) {
                    coverquestion = question;
                    setcoverquestion(question);
                  }
                });
                return question.question.search("(cover photo)") == -1;
              }
            );
            setuseranswer(filter);
            setcoverquestion(coverquestion);
            setreport(res.data.userreport[0]);
            setLoading(false);
            getTime(res.data.userreport[0].timeofinspection);
            setcoverphoto(
              coverquestion.question_image
                ? coverquestion.question_image[0].imagefile
                : ""
            );
            setcovername(
              coverquestion.question_image
                ? coverquestion.question_image[0].imagename
                : ""
            );
          }
        });
    }
  };
  // get all questions
  const getallquestions = () => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/user-question-list/question-list`)
      .then((res) => {
        if (res.data.statuscode == 200) {
          setquestions(res.data.questions);
        }
      });
  };

  //Generate pdf
  const generatepdf = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/user-report/generate-user-report-pdf`,
        data
      )
      .then((res) => {
        console.log(res);
        if (res.data.statuscode == 200) {
          setLoading(false);
          window.open(res.data.reportpath);
        } else {
          setLoading(false);
          toast("server error");
        }
      });
  };

  useEffect(() => {
    getEditdata();
    getallquestions();
  }, []);
  return isAuthenticated ? (
    showloading ? (
      <div className="container  m-0 p-0">
        <Loading />
      </div>
    ) : history.location.state != undefined ? (
      <div className="col-md-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">View Report</h4>
            <form className="forms-sample">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">Full Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName1"
                        placeholder="Full Name"
                        name="fullname"
                        value={report.fullname ? report.fullname : ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">Company Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputcompanyname"
                        placeholder="Company Name"
                        name="companyname"
                        value={report.companyname ? report.companyname : ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">Email Address</label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail3"
                        placeholder="Email Address"
                        name="emailaddress"
                        value={report.emailaddress ? report.emailaddress : ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">Phone Number</label>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputPhone"
                        placeholder="Phone Number"
                        name="phonenumber"
                        value={report.phonenumber ? report.phonenumber : ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">Address</label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        placeholder="Address"
                        id="exampleTextarea1"
                        rows="4"
                        value={report.address ? report.address : ""}
                        name="address"
                        readOnly
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">
                      Address of inspection
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        className="form-control"
                        id="exampleTextarea1addressins"
                        rows="4"
                        placeholder="Address of inspection"
                        name="addressofinspection"
                        value={
                          report.addressofinspection
                            ? report.addressofinspection
                            : ""
                        }
                        readOnly
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">
                      Reference Number
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputName1"
                        placeholder="Reference Number"
                        name="referencenumber"
                        value={
                          report.referencenumber ? report.referencenumber : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">Claim Number</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputcompanyname"
                        placeholder="Claim Number"
                        name="claimnumber"
                        value={report.claimnumber ? report.claimnumber : ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">
                      Date of inspection
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="date"
                        style={{ border: "1px solid rgb(222,226,230)" }}
                        readOnly
                        value={
                          report.dateofinspection
                            ? new Date(report.dateofinspection)
                                .toLocaleDateString("pt-br")
                                .split("/")
                                .reverse()
                                .join("-")
                            : ""
                        }
                        name="dateofinspection"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">
                      Time of inspection
                    </label>
                    <div className="col-sm-9">
                      <input
                        style={{ border: "1px solid rgb(222,226,230)" }}
                        type="time"
                        value={time}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">Date of event</label>
                    <div className="col-sm-9">
                      <input
                        type="date"
                        style={{ border: "1px solid rgb(222,226,230)" }}
                        value={
                          report.dateofevent
                            ? new Date(report.dateofevent)
                                .toLocaleDateString("pt-br")
                                .split("/")
                                .reverse()
                                .join("-")
                            : ""
                        }
                        name="dateofevent"
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 form-label">
                      Event related opening
                    </label>
                    <div className="col-sm-9">
                      <input
                        style={{ border: "1px solid rgb(222,226,230)" }}
                        type="text"
                        name="eventrelatedopening"
                        value={
                          report.eventrelatedopening
                            ? report.eventrelatedopening
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="form-group">
                <label htmlFor="exampleTextarea2">Other</label>
                <input
                  className="form-control"
                  placeholder="Other"
                  id="exampleTextarea2"
                  rows="4"
                  value={report.other ? report.other : ""}
                  name="other"
                  readOnly
                ></input>
              </div>
              <hr style={{ borderTop: "2px solid red" }} />

              <div style={{ fontSize: "20px" }}>
                Picture of property or roof (cover
                photo):&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                {coverphoto && (
                  <img
                    style={{
                      display: "inline-block",
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                    }}
                    src={coverphoto}
                    alt={""}
                  />
                )}
              </div>

              <hr style={{ borderTop: "2px solid black" }} />
              {/* {useranswer.map((question, i) => (
                <div key={i}>
                  <Editquestion
                    question={question}
                    index={i}
                    arraylen={useranswer.length}
                    childdata={childdata}
                  />
                  <hr style={{ borderTop: "2px solid black" }} />
                </div>
              ))} */}
              {questions.map((question, i) => {
                var que = [];
                let match = false;
                for (const item of useranswer) {
                  if (item.question === question.question) {
                    match = true;
                    delete item["question_id"];
                    item["question_id"] = question._id;
                    item["question_number"] = question.question_number;
                    item["add_photo"] = question.add_photo;
                    item["actionbar_title"] = question.actionbar_title;
                    que.push(
                      <div key={question._id}>
                        <Editquestion
                          reportQuestions={useranswer}
                          question={item}
                          index={question.question_number}
                          arraylen={useranswer.length}
                          childdata={childdata}
                        />
                        <hr style={{ borderTop: "2px solid black" }} />
                      </div>
                    );
                    break;
                  }
                }
                return que[0];
              })}
              {/* <p className="btn btn-primary me-2" onClick={addquestion}>Add Question</p> */}
              <br />
              <button
                type="submit"
                className="btn btn-primary me-2"
                onClick={generatepdf}
              >
                Generate PDF
              </button>
            </form>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={2100}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={"dark"}
        />
      </div>
    ) : (
      <Redirect to="/dashboard" />
    )
  ) : (
    <Redirect to="/" />
  );
}

export function Editquestion(props) {
  const history = useHistory();

  let [img, setimg] = useState([]);
  const [question, setquestion] = useState({
    question_id: "",
    question: "",
    lastupdateddate: "",
    question_option: [],
    question_image: [],
    categoryname: "",
    categoryid: "",
    furthercomment: undefined,
    optionalanswer: undefined,
  });
  const [imghandler, setimagehandler] = useState([]);

  const geteditquestion = (question) => {
    setquestion(question);
    if (question.question_option != undefined) {
      // setOption(question.question_option);
      // option = question.question_option;
    }
    if (question.question_image != undefined) {
      setimg(question.question_image);
      let changeimg = [];
      for (let i = 0; i < question.question_image.length; i++) {
        changeimg.push(false);
      }
      setimagehandler(changeimg);
      img = question.question_image;
    }
  };

  useEffect(() => {
    geteditquestion(props.question);
  }, []);

  // const all_data = () => {
  //   let arraynumber = {
  //     ...question,
  //     question_image: img,
  //     index: props.index,
  //   };
  //   props.childdata(arraynumber);
  // };

  //generate image input field
  const createimgUI = () =>
    img &&
    img.map((image, j) => (
      <div style={{ width: "205px", marginLeft: "1%" }} key={j}>
        <div className="form-group ">
          <label htmlFor={`img${j}`} className="col-sm-3 col-form-label">
            Image{j + 1}
          </label>
          <div className="col-sm-9 d-flex justify-content-between">
            {imghandler[j] ? (
              <p key={j}>
                {image && image.imagefile ? (
                  <img
                    src={image.imagefile}
                    width={100}
                    height={100}
                    alt={image.imagename}
                  />
                ) : (
                  ""
                )}{" "}
              </p>
            ) : (
              <p key={j}>
                {image && image.imagefile ? (
                  <img
                    src={image.imagefile}
                    width={100}
                    height={100}
                    alt={image.imagename}
                  />
                ) : (
                  ""
                )}{" "}
              </p>
            )}
          </div>
        </div>
      </div>
    ));

  //  <div className="form-group">
  //                         <input
  //                             type="file"
  //                             accept="image/*"
  //                             style={{ display: 'none' }}
  //                             id="contained-button-file"
  //                             onChange={(e) => handleimg(e, j)} name='profileimage' required
  //                         />
  //                         <label htmlFor="contained-button-file">
  //                             <Button variant="contained" color="primary" component="span">
  //                                 Upload
  //                             </Button>
  //                         </label>
  //                         </div>
  // <input type="file" onChange={(e) => handleimg(e, j)} />
  //convert base64

  return (
    <div className="col-md-12 grid-margin ">
      <h4>Question {question.question_number}</h4>
      <div className="forms-sample">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group row">
              <label className="col-sm-3 form-label">Question</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputMobile"
                  placeholder="Question"
                  name="question"
                  readOnly
                  defaultValue={question.question}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group row">
              <label className="col-sm-3 form-label">Further comment</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail2"
                  placeholder="Further comment"
                  name="furthercomment"
                  readOnly
                  value={question.furthercomment ? question.furthercomment : ""}
                />
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-md-6">
            <div className="form-group row">
              <label className="col-sm-3 form-label">Category name</label>
              <div className="col-sm-9">
                <input
                  className="form-control w-75"
                  id="exampleInputcategory"
                  aria-label="Default select example"
                  value={question.categoryname ? question.categoryname : "-"}
                  name="categoryname"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group row">
              <label className="col-sm-3 form-label">Answer</label>
              <div className="col-sm-9">
                <input
                  className="form-control w-75"
                  id="exampleInputcategory"
                  aria-label="Default select example"
                  value={
                    question.question_option.length > 0
                      ? question.question_option[0].option
                      : "-"
                  }
                  name="question_option"
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="form-group">
          <label
            htmlFor="exampleInputUsername2"
            className="col-sm-3 form-label"
          >
            Optional answer
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              id="exampleInputUsername2"
              placeholder="Optional answer"
              name="optionalanswer"
              readOnly
              value={question.optionalanswer ? question.optionalanswer : ""}
            />
          </div>
        </div>
        <br />
        {/* <h5>Images</h5> */}
        <div className="d-flex align-content-start flex-wrap">
          {createimgUI()}
        </div>
        <br />
        <div className="form-group">
          <div className="col-sm-9"></div>
        </div>
      </div>
    </div>
  );
}
