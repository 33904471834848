import React, { useState, useEffect } from "react";
import axios from "axios";
import crypto from "crypto";
import { useHistory, Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@material-ui/core/Button";
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from "constants";
import Subscription from "../../auth/Subscription";
import Loading from "../../loading/Loading";
export default function Editreport() {
  let userid = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  const isAuthenticated = localStorage.getItem("loggedin");
  const history = useHistory();
  const [report, setreport] = useState({
    registrationId: "",
    companyname: "",
    fullname: "",
    referencenumber: "",
    phonenumber: "",
    address: "",
    emailaddress: "",
    other: "",
    referencenumber: "",
    claimnumber: "",
    eventrelatedopening: false,
    dateofinspection: "",
    timeofinspection: "",
    dateofevent: "",
    addressofinspection: "",
    useranswer: [],
  });
  let [userreport, setuserreport] = useState([]);
  let [showPlan, setshowPlan] = useState(false);
  let [subscriptionPlans, setsubscriptionPlans] = useState([]);
  let [filledreport, setfilledreport] = useState([]);
  let [status, setstatus] = useState(false);
  const [showloading, setLoading] = useState(false);
  const [time, setTime] = useState("");
  const [coverimage, setcoverimage] = useState("");
  const [{ alt, src }, setcoverphoto] = useState({
    src: "",
    alt: "Upload an Image",
  });
  const [eventopening, seteventopening] = useState(false);
  const getallquestions = () => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/user-question-list/question-list`)
      .then((res) => {
        if (res.data.statuscode == 200) {
          setuserreport(res.data.questions);
          setreport({ ...report, userreport: res.data.questions });
        }
      });
  };
  const childdata = (data) => {
    // console.log("data:", data);
    if (
      (data.question_option.length > 0 ||
        data.optionalanswer ||
        data.furthercomment) != false ||
      data.is_complusory_question ||
      data.question_image.length > 0
    ) {
      filledreport[data.index] = data;
    }
    userreport[data.index] = data;
  };
  const removecover = () => {
    setcoverphoto("");
    setcoverimage({
      question_id: (
        m = Math,
        d = Date,
        h = 16,
        s = (s) => m.floor(s).toString(h)
      ) =>
        s(d.now() / 1000) +
        " ".repeat(h).replace(/./g, () => s(m.random() * h)),
      question: "Picture of property or roof(cover photo)",
      question_option: [],
      question_image: [],
      categoryname: "",
      add_photo: true,
      actionbar_title: "Optional Question",
      categoryid: "",
      furthercomment: "",
      optionalanswer: "",
    });
  };

  const handleCoverphoto = async (e) => {
    if (e.target.value) {
      const file = e.target.files[0];
      setcoverphoto({
        src: URL.createObjectURL(file),
        alt: file.name,
      });
      // const base_64 = await convertBase64(file);
      setcoverimage(file);
    }
  };
  //get data for edit from database

  //onChange for main object
  const handlereport = (e) => {
    if (e.target.name == "phonenumber") {
      if (e.keyCode === "38" || e.keyCode === "40") {
        e.preventDefault();
      }
      if (e.target.value.length == 12) {
        return 0;
      }
    }
    setreport({ ...report, [e.target.name]: e.target.value });
  };

  const reset = () => { };
  useEffect(() => {
    getallquestions();
  }, []);
  //submit updated data

  const submitReport = (e) => {
    e.preventDefault();
    // axios
    //   .get(
    //     `${process.env.REACT_APP_API_URI}/subscription/getplans`, { headers: { 'x-access-token': token } }
    //   )
    // .then((res) => {
    //   console.log("submited res", res.data.data);
    // if (res.data.statuscode == 200) {
    // if (res.data.data.user_subscription.is_subscribed == false) {
    //   // show plans
    //   // console.log("show plans")
    //   setsubscriptionPlans(res.data.data.subscriptions)
    //   setshowPlan(true)
    // } else {
    //   setsubscriptionPlans(res.data.data.subscriptions)
    // }
    // }
    // })
    // submit report 
    let Formdata = new FormData();
    if (!report.fullname) {
      toast("fullname is required field");
      return 0;
    } else if (!report.address) {
      toast("address is required field");
      return 0;
    } else if (!report.addressofinspection) {
      toast("address of inspection is required field");
      return 0;
    } else if (!report.dateofinspection) {
      toast("date of inspection is required field");
      return 0;
    } else if (!time) {
      toast("time of inspection is required field");
      return 0;
    }

    for (let i = 0; i < report.useranswer.length; i++) {
      if (report.useranswer[i].index) {
        delete report.useranswer[i].index;
      }
    }
    let imagerequired = true;
    let terminate = false;
    filledreport.map((question) => {
      if (question.question_image) {
        if (!terminate) {
          for (let x = 0; x < question.question_image.length; x++) {
            if (
              (question.question_image[x].imagefile == "" ||
                question.question_image[x].imagefile == " ") &&
              !question.question_image[x].iscoverphoto
            ) {
              toast(
                "please select image in question " + question.question_number
              );
              imagerequired = false;
              terminate = true;
              break;
            }
            Formdata.append(
              `report_image-${question.question_id}`,
              question.question_image[x].imagefile
            );
            if (
              (question.question_image[x].imagefile != "" ||
                question.question_image[x].imagefile != " ") &&
              question.question_option.length == 0
            ) {
              toast("Please answer the question " + question.question_number);
              terminate = true;
              break;
            }
          }
        }
      }
    });
    if (!imagerequired || terminate) {
      return 0;
    }
    if (coverimage) {
      var coverquestion = {
        question_id: "612f428278779f0e68fb54f0",
        question: "Picture of property or roof(cover photo)",
        question_option: [],
        question_image: [],
        categoryname: null,
        categoryid: null,
        furthercomment: null,
        optionalanswer: "",
        add_photo: true,
        actionbar_title: "Optional Question",
      };
      Formdata.append("cover_image", coverimage);
    } else {
      var coverquestion = {
        question_id: "612f428278779f0e68fb54f0",
        question: "Picture of property or roof(cover photo)",
        question_option: [],
        question_image: [],
        categoryname: null,
        categoryid: null,
        furthercomment: null,
        optionalanswer: "",
        add_photo: true,
        actionbar_title: "Optional Question",
      };
    }
    let filled = [];
    for (let i = 0; i < filledreport.length; i++) {
      if (filledreport[i]) {
        filled.push(filledreport[i]);
        if (
          filledreport[i].question_option.length == 0 &&
          filledreport[i].is_complusory_question
        ) {
          toast("please fill all complusory question");
          return 0;
        }
        if (
          filledreport[i].question_number >= 22 &&
          filledreport[i].question_number <= 49 &&
          (filledreport[i].question_option.length > 0 ||
            filledreport[i].question_image.length > 0 ||
            filledreport[i].optionalanswer != "") &&
          (filledreport[i].categoryname == null ||
            filledreport[i].categoryname == "")
        ) {
          toast(
            "please select category for question nulmer " +
            filledreport[i].question_number
          );
          return 0;
        }
      }
    }
    userreport = [coverquestion, ...filled];
    report["useranswer"] = userreport;
    report["registrationId"] = userid;
    // report["userSubscription_id"] = res.data.data.user_subscription.userSubscriptionId;
    if (time) {
      if (parseInt(time.split(":")[0]) > 12) {
        report["timeofinspection"] = String(
          parseInt(time.split(":")[0]) - 12
        ).concat(":", time.split(":")[1], " PM");
      } else if (parseInt(time.split(":")[0]) == 0) {
        report["timeofinspection"] = String(
          parseInt(time.split(":")[0]) + 12
        ).concat(":", time.split(":")[1], " AM");
      } else if (parseInt(time.split(":")[0]) < 12) {
        report["timeofinspection"] = time.concat(" AM");
      } else {
        report["timeofinspection"] = time.concat(" PM");
      }
    }
    // const reportstate=window.confirm("Are you completed report?")
    if (status) {
      report["isinprogress"] = false;
      report["iscompleted"] = true;
      report["isarchive"] = false;
    } else {
      report["isinprogress"] = true;
      report["iscompleted"] = false;
      report["isarchive"] = false;
    }
    if (eventopening) {
      report["eventrelatedopening"] = "Yes";
    } else {
      report["eventrelatedopening"] = "No";
    }
    Formdata.append("report", JSON.stringify([report]));
    // console.log("submit", report);
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/user-report/save-report?token=${token}`,
        Formdata
      )
      .then((res) => {
        setLoading(false);
        // console.log("submited res", res.data.userreport);
        if (res.data.statuscode == 200) {
          toast(res.data.message);
          history.push("/reportlist");
        }
      });

  };

  const DisablePopup = () => {
    setshowPlan(false)
  }

  return isAuthenticated ? (showloading ? (
    <div className="container  m-0 p-0">
      <Loading />
    </div>) :
    <div className="col-md-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Add New Report</h4>
          <form className="forms-sample">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 form-label">
                    Full Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Full Name"
                      required={true}
                      name="fullname"
                      value={report.fullname}
                      onChange={handlereport}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 form-label">Company Name</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputcompanyname"
                      placeholder="Company Name"
                      name="companyname"
                      value={report.companyname}
                      onChange={handlereport}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 form-label">Email Address</label>
                  <div className="col-sm-9">
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail3"
                      placeholder="Email Address"
                      name="emailaddress"
                      value={report.emailaddress}
                      onChange={handlereport}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 form-label">Phone Number</label>
                  <div className="col-sm-9">
                    <input
                      type="number"
                      className="form-control"
                      id="exampleInputPhone"
                      placeholder="Phone Number"
                      name="phonenumber"
                      value={report.phonenumber}
                      onChange={handlereport}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 form-label">
                    Address<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="col-sm-9">
                    <textarea
                      required
                      className="form-control"
                      placeholder="Address"
                      id="exampleTextarea1"
                      rows="4"
                      style={{ "resize": "none" }}
                      value={report.address}
                      name="address"
                      onChange={handlereport}
                    >
                      {" "}
                    </textarea>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 form-label">
                    Address of inspection<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="col-sm-9">
                    <textarea
                      required
                      className="form-control"
                      id="exampleTextarea1addressins"
                      rows="4"
                      style={{ "resize": "none" }}
                      placeholder="Address of inspection"
                      name="addressofinspection"
                      value={report.addressofinspection}
                      onChange={handlereport}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 form-label">
                    Date of inspection<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="date"
                      style={{ border: "1px solid rgb(222,226,230)" }}
                      onChange={handlereport}
                      value={new Date(report.dateofinspection)
                        .toLocaleDateString("pt-br")
                        .split("/")
                        .reverse()
                        .join("-")}
                      name="dateofinspection"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 form-label">
                    Time of inspection<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="col-sm-9">
                    <input
                      style={{ border: "1px solid rgb(222,226,230)" }}
                      type="time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 form-label">Date of event</label>
                  <div className="col-sm-9">
                    <input
                      type="date"
                      style={{ border: "1px solid rgb(222,226,230)" }}
                      onChange={handlereport}
                      value={new Date(report.dateofevent)
                        .toLocaleDateString("pt-br")
                        .split("/")
                        .reverse()
                        .join("-")}
                      name="dateofevent"

                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 form-label">
                    Event related opening
                  </label>
                  <div className="col-sm-9">
                    <input
                      style={{ border: "1px solid rgb(222,226,230)" }}
                      type="checkbox"
                      name="eventrelatedopening"
                      defaultChecked={eventopening}
                      onChange={() => seteventopening(!eventopening)}

                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 form-label">
                    Reference Number
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputreferencenumber"
                      placeholder="Reference Number"
                      name="referencenumber"
                      value={report.referencenumber}
                      onChange={handlereport}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 form-label">Claim Number</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      placeholder="Claim Number"
                      id="exampleother"
                      value={report.claimnumber}
                      name="claimnumber"
                      onChange={handlereport}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 form-label">Other</label>
                <div className="col-sm-9">
                  <input
                    className="form-control"
                    placeholder="Other"
                    id="exampleother"
                    value={report.other}
                    name="other"
                    onChange={handlereport}
                  ></input>
                </div>
              </div>
            </div>
            <hr style={{ borderTop: "2px solid red" }} />
            <div style={{ fontSize: "20px" }}>
              Picture of property or roof (cover photo):&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              {src && (
                <img
                  style={{
                    display: "inline-block",
                    width: "150px",
                    height: "150px",
                    borderRadius: "50%",
                  }}
                  src={src}
                  alt={alt}
                />
              )}
            </div>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id="contained-button-file"
              onChange={handleCoverphoto}
              name="coverphoto"
            />
            <br />
            <br />
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span">
                Upload
              </Button>
            </label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <svg
              width="40"
              height="20"
              fill="currentColor"
              className="bi bi-trash-fill"
              viewBox="0 0 20 16"
              onClick={removecover}
              style={{ cursor: "pointer" }}
            >
              <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
            </svg>
            <hr style={{ borderTop: "2px solid black" }} />
            {userreport.map((question, i) => (
              <div key={i}>
                <Editquestion
                  question={{
                    question_id: question._id,
                    question_number: question.question_number,
                    question: question.question,
                    add_photo: question.add_photo,
                    actionbar_title: question.actionbar_title,
                    furthercomment: "",
                    optionalanswer: "",
                    is_complusory_question: question.is_complusory_question,
                    categoryid: null,
                    categoryname: null,
                    question_option: question.question_option,
                    question_image: [],
                  }}
                  index={i}
                  arraylen={userreport.length}
                  add_photo={question.add_photo}
                  question_number={question.question_number}
                  childdata={childdata}
                />
                <hr style={{ borderTop: "2px solid black" }} />
              </div>
            ))}
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={() => setstatus(!status)}
                name="status"
                defaultChecked={status}
                id="flexCheckDefault"
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                I have completed this report
              </label>
            </div>
            <br />
            <button
              type="submit"
              className="btn btn-primary me-2"
              onClick={submitReport}
            >
              Submit
            </button>
            <div className="btn btn-light" onClick={reset}>
              Reset
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2100}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={"dark"}
      />
      {showPlan && <Subscription plans={subscriptionPlans} DisablePopup={DisablePopup} />}
    </div>
  ) : (
    <Redirect to="/" />
  );
}

export function Editquestion(props) {
  const history = useHistory();
  let [options, setOptions] = useState([]);
  let [img, setimg] = useState([]);
  const [question, setquestion] = useState({
    question_id: "",
    question_number: "",
    question: "",
    add_photo: false,
    actionbar_title: "",
    question_option: [],
    question_image: [],
    categoryname: "",
    categoryid: "",
    furthercomment: "",
    optionalanswer: "",
  });
  const [add_photo, setaddphoto] = useState(false);
  const [imghandler, setimagehandler] = useState([]);
  const [categories, setcategories] = useState([]);
  const [question_number, setnumber] = useState("");
  const [count, setcount] = useState(0);
  const geteditquestion = (question) => {
    setquestion({ ...question, question_option: [] });

    if (props.question.question_image != undefined) {
      setimg(props.question.question_image);
      let changeimg = [];
      for (let i = 0; i < props.question.question_image.length; i++) {
        changeimg.push(false);
      }
      setimagehandler(changeimg);
      img = props.question.question_image;
    }
  };
  const getcategory = () => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/category/category-list`)
      .then((res) => {
        if (res.data.statuscode == 200) {
          setcategories(res.data.category);
        }
      });
  };
  useEffect(() => {
    geteditquestion(props.question);
    setOptions(props.question.question_option);
    setaddphoto(props.add_photo);
    setnumber(props.question_number);
    getcategory();
  }, []);

  const all_data = () => {
    let arraynumber = {
      ...question,
      question_image: img,
      index: props.index,
    };
    // console.log("arraynumber", arraynumber);
    props.childdata(arraynumber);
  };

  //for handling static inputs
  const handleinp = (e) => {
    if (e.target.name == "question_option") {
      let newoption = options[e.target.value];
      setquestion({ ...question, [e.target.name]: [newoption] });
    } else if (e.target.name == "categoryname" && e.target.value) {
      categories.map((ele) => {
        if (e.target.value == ele.categoryname) {
          setquestion({
            ...question,
            categoryid: ele._id,
            categoryname: e.target.value,
          });
        }
      });
    } else {
      setquestion({ ...question, [e.target.name]: e.target.value });
    }
  };

  //Dynamic images handle
  //add new image
  const addimgClick = () => {
    // add img
    setcount(count + 1);
    if (img) {
      setimg([
        ...img,
        {
          imagename: "",
          extension: "",
          imagefile: "",
          iscoverphoto: false,
          src: "",
        },
      ]);
    } else {
      setimg([
        {
          imagename: "",
          extension: "",
          imagefile: "",
          iscoverphoto: false,
          src: "",
        },
      ]);
    }
  };

  //onchange function for image field
  const handleimg = async (e, index) => {
    if (e.target.value) {
      const file = e.target.files[0];
      const list = [...img];
      const changed = [...imghandler];
      changed[index] = true;
      setimagehandler(changed);
      list[index]["src"] = URL.createObjectURL(file);
      list[index]["imagename"] = file.name.split(".")[0];
      list[index]["imagefile"] = file;
      setimg(list);
      // setquestion({ ...question, question_image: img });
    }
  };
  //remove image field
  const removeimgClick = (index) => {
    setcount(count - 1);
    const list = [...img];
    list.splice(index, 1);
    setimg(list);
  };

  //generate image input field
  const createimgUI = () =>
    img &&
    img.map((image, j) => (
      <div style={{ width: "210px", marginLeft: "1%" }} key={j}>
        <div className="form-group ">
          <label htmlFor={`img${j}`} className="col-sm-3 col-form-label">
            Image{j + 1}
          </label>
          <div className="col-sm-9 d-flex justify-content-between">
            {imghandler[j] ? (
              <p key={j}>
                {image && image.imagefile ? (
                  <img src={image.src} width={100} height={100} alt=" " />
                ) : (
                  ""
                )}{" "}
              </p>
            ) : (
              <p key={j}>
                {image && image.imagefile ? (
                  <img src={image.src} width={100} height={100} alt=" " />
                ) : (
                  ""
                )}{" "}
              </p>
            )}
            {add_photo ? (
              <div>
                <svg
                  width="40"
                  height="20"
                  fill="currentColor"
                  className="bi bi-trash-fill"
                  viewBox="0 0 20 16"
                  onClick={() => removeimgClick(j)}
                  style={{ cursor: "pointer" }}
                >
                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                </svg>
              </div>
            ) : (
              ""
            )}
          </div>
          {add_photo ? (
            <div className="form-group">
              <input
                type="file"
                accept="image/*"
                // style={{ display: 'none' }}
                id={`contained-button-file${j}`}
                onChange={(e) => handleimg(e, j)}
                name={`profileimage${(question._id, j)}`}
                required
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    ));
  // <input type="file" onChange={(e) => handleimg(e, j)} />

  return (
    <div className="col-md-12 grid-margin ">
      <h4>
        Question {question.question_number}
        {question.is_complusory_question && (
          <span style={{ color: "red" }}>*</span>
        )}
      </h4>
      <div className="forms-sample">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group row">
              <label className="col-sm-3 form-label">Question</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputMobile"
                  placeholder="Question"
                  name="question"
                  readOnly
                  defaultValue={question.question}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group row">
              <label className="col-sm-3 form-label">Further comment</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputEmail2"
                  placeholder="Further comment"
                  name="furthercomment"
                  readOnly={options.length == 0 ? false : true}
                  defaultValue={question.furthercomment}
                  onChange={handleinp}
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-6">
            <div className="form-group row">
              <label className="col-sm-3 form-label">Category name</label>
              <div className="col-sm-9">
                <select
                  className="form-select w-75"
                  id="exampleInputcategory"
                  aria-label="Default select example"
                  disabled={
                    !(
                      22 <= question.question_number &&
                      question.question_number <= 49
                    )
                  }
                  value={question.categoryname ? question.categoryname : ""}
                  name="categoryname"
                  onChange={handleinp}
                >
                  {!question.categoryname && <option value="">Select</option>}
                  <option value="General">General</option>
                  <option value="Event">Event</option>
                  <option value="Maintenance">Maintenance</option>
                  <option value="Defect">Defect</option>
                  <option value="Safety">Safety</option>
                </select>
              </div>
            </div>
          </div>
          {options.length > 0 && (
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 form-label">Answer</label>
                <div className="col-sm-9">
                  {
                    <select
                      className="form-select w-75"
                      id="exampleInputcategory"
                      aria-label="Default select example"
                      name="question_option"
                      required={
                        question.is_complusory_question
                          ? question.is_complusory_question
                          : false
                      }
                      onChange={handleinp}
                    >
                      {question.question_option.length == 0 && (
                        <option value="">Select</option>
                      )}
                      {options &&
                        options.map((ele, i) => (
                          <option key={i} value={i}>
                            {ele.option}
                          </option>
                        ))}
                    </select>
                  }
                </div>
              </div>
            </div>
          )}
        </div>
        <br />
        <div className="form-group">
          <label
            htmlFor="exampleInputUsername2"
            className="col-sm-3 form-label"
          >
            Optional answer
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              id="exampleInputUsername2"
              placeholder="Optional answer"
              name="optionalanswer"
              readOnly={
                !(
                  22 <= question.question_number &&
                  question.question_number <= 49
                )
              }
              value={question.optionalanswer}
              onChange={handleinp}
            />
          </div>
        </div>
        <br />
        <div className="d-flex align-content-start flex-wrap">
          {createimgUI()}
        </div>
        <br />
        <div className="form-group">
          <div className="col-sm-9">
            {add_photo && count < 4 ? (
              <button
                type="button"
                className=" btn btn-success"
                id="addAnswerid"
                onClick={addimgClick}
              >
                Add Image
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        {all_data()}
      </div>
    </div>
  );
}
