import React, { Component } from 'react'
import loading from './ajax-loader.gif'
export default class Loading extends Component {
    render() {
        return (
            <div className="text-center m-auto">
                <img src={loading} alt="Loading" />
            </div>
        )
    }
}
