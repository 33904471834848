import React, { useState, useEffect } from 'react'
import { Link, useHistory, Redirect } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Img from '../../image/bg4.JPG'
function Login() {
    const isAuthenticated = localStorage.getItem("loggedin");
    let [emailerr, setemailerr] = useState(false)
    let [passworderr, setpassworderr] = useState(false)
    const history = useHistory()
    const [admin, setAdmin] = useState({ emailaddress: "", password: "" })
    const [passwordShown, setPasswordShown] = useState(false);
    const handleChange = (e) => {
        setAdmin({ ...admin, [e.target.name]: e.target.value })
    }
    const Submit = () => {
        setemailerr(false)
        setpassworderr(false)
        if (admin.emailaddress && admin.password) {
            axios.post(`${process.env.REACT_APP_API_URI}/user-registration/user-login`, admin).then((res) => {
                console.log(res)
                if (res.data.statuscode == 200) {
                    localStorage.setItem('loggedin', true);
                    localStorage.setItem('user', res.data.user._id);
                    localStorage.setItem('useremail', admin.emailaddress);
                    localStorage.setItem('token', res.data.user.token);
                    history.push('/dashboard')
                }
                // else if(res.data.statuscode==401){
                //   history.push('/subscription')
                // }else if(res.data.statuscode==404){
                //   history.push('/signup')
                // }
                else {
                    toast(res.data.status)
                }
            })

        } else {
            if (admin.emailaddress == "" || admin.emailaddress == " ") {
                setemailerr(true)
            }
            if (admin.password == "" || admin.password == " ") {
                setpassworderr(true)
            }
        }

    }

    return (
        !isAuthenticated ?
            <div id="layoutAuthentication">
                <div id="layoutAuthentication_content" style={{backgroundImage:`url(`+Img+")",backgroundSize:"cover"}}>
                    <main >
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5">
                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-header"><h3 className="text-center font-weight-light my-4">Login</h3></div>
                                        <div className="card-body">
                                            <form>
                                                <div className="form-floating mb-3">
                                                    <input type="email" className="form-control" id="floatingInput" name="emailaddress" value={admin.emailaddress} onChange={handleChange} placeholder="name@example.com" />
                                                    <label htmlFor="floatingInput">Email address</label>
                                                    {emailerr ? <p style={{ color: 'red', fontSize: "14px" }}>Email is required</p> : ""}
                                                </div>
                                                <div className="form-floating mb-3">
                                                    <input type={passwordShown ? "text" : "password"} className="form-control" id="floatingPassword" name="password" value={admin.password} onChange={handleChange} placeholder="Password" />
                                                    <input type="checkbox" checked={passwordShown} onChange={() => setPasswordShown(!passwordShown)} className="my-3" /> Show password
                                                    <label htmlFor="floatingPassword">Password</label>
                                                    {passworderr ? <p style={{ color: 'red', fontSize: "14px" }}>Password is required</p> : ""}
                                                </div>
                                                <div className="form-check mb-3">
                                                    <input className="form-check-input" id="inputRememberPassword" type="checkbox" value="" />
                                                    <label className="form-check-label" htmlFor="inputRememberPassword">Remember Password</label>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                                                    <Link className="small" to="/forget-password">Forgot Password?</Link>
                                                    <a className="btn btn-primary" onClick={Submit} >Login</a>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="card-footer text-center py-3">
                                            <div className="small"><Link to="/signup">Need an account? Sign up!</Link></div>
                                            {/* <div className="small"><Link to="/subscription">already signup? Subscribe now!</Link></div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div id="layoutAuthentication_footer">
        <footer className="py-4 bg-dark mt-auto">
          <div className="container-fluid px-4">
            <div className="d-flex align-items-center justify-content-between small">
              <div className="text-muted">
                Copyright &copy; support@roofinspectionpro.net
              </div>
              <div className="text-muted">
              From Australia&nbsp;&nbsp; :<a href="tel:1300 727 054" style={{ textDecoration:"none"}}>1300 727 054</a><br/>
              From Overseaas:<a href="tel:+61 478 566 998" style={{ textDecoration:"none"}}>+61 478 566 998</a>
               
              </div>
            </div>
          </div>
        </footer>
        <ToastContainer
          position="top-center"
          autoClose={2100}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={'dark'}
        />
      </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2100}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme={'dark'}
                />
            </div> : <Redirect to='/dashboard' />
    )
}

export default Login