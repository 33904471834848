import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
export default function Dashboard() {
    const userid = localStorage.getItem('user')
    const isAuthenticated = localStorage.getItem("loggedin");
    const [details, setdetails] = useState({ Reports: "", profiledetail: "" })
    const getdetails = () => {
        axios.post(`${process.env.REACT_APP_API_URI}/user-report/get-reports-count`, { userid: userid }).then((res) => {
            if (res.data.statuscode == 200) {
                setdetails(res.data.data)
            }
        })
    }
    useEffect(() => {
        getdetails()
    }, [])
    return (
        isAuthenticated ?
            <main>
                <div className="container-fluid px-4">
                    <h1 className="mt-4">Dashboard</h1>
                    <ol className="breadcrumb mb-4">
                        <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                    <div className="row ">
                        <div className="col-xl-4 col-md-6">
                            <div className="card bg-primary text-white mb-4">
                                <div className="card-body">Profile Details</div>
                                <div className="card-footer d-flex align-items-center justify-content-between">
                                    <p className="small text-white stretched-link" >{details && details.profiledetail ? details.profiledetail : 'profile not available'}</p>

                                    <div className="small text-white"><i className="fas fa-angle-right"><Link className="small text-white stretched-link" to='/profile'>Visit-Profile</Link></i></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="card bg-warning text-white mb-4">
                                <div className="card-body">Total Reports</div>
                                <div className="card-footer d-flex align-items-center justify-content-between">
                                    <p className="small text-white stretched-link" > {details && details.Reports !== 0 ? `You have total ${details.Reports} reports` : 'report not available'} </p>
                                    <div className="small text-white"><i className="fas fa-angle-right"><Link className="small text-white stretched-link" to='/reportlist'>Visit-Reports</Link></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main> : <Redirect to='/' />
    )
}
