import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function UnSubscribe() {
    const userid = localStorage.getItem('user')
    const isAuthenticated = localStorage.getItem("loggedin");
    let [emailerr, setemailerr] = useState(false)
    let [passworderr, setpassworderr] = useState(false)
    const [admin, setAdmin] = useState({ title: "", desc: "" })

    const handleChange = (e) => {
        setAdmin({ ...admin, [e.target.name]: e.target.value })
    }
    const Submit = () => {
        admin['userid'] = userid
        setemailerr(false)
        setpassworderr(false)
        if (admin.title && admin.desc) {
            axios.post(`${process.env.REACT_APP_API_URI}/subscription/cancel-subscription`, admin).then((res) => {
                if (res.data.statuscode == 200) {
                    setAdmin({ title: "", desc: "" })
                    toast("Successfully sent an email to the admin.")
                }
            })

        } else {
            if (admin.title == "" || admin.title == " ") {
                setemailerr(true)
            }
            if (admin.desc == "" || admin.desc == " ") {
                setpassworderr(true)
            }
        }

    }

    return (isAuthenticated ?
        <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="card  mt-5">
                                    <div className="card-header"><h3 className="text-center font-weight-light my-4">Change/Cancel Subscription</h3></div>
                                    <div className="card-body">
                                        <form>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" id="floatingInput" name="title" value={admin.title} onChange={handleChange} placeholder="Title" />
                                                <label htmlFor="floatingInput">Title</label>
                                                {emailerr ? <p style={{ color: 'red', fontSize: "14px" }}>Title is required</p> : ""}
                                            </div>
                                            <div className="form-floating mb-3">
                                                <textarea rows={4} type="text" className="h-100 form-control" id="floatingPassword" name="desc" value={admin.desc} onChange={handleChange} placeholder="description" />
                                                <label htmlFor="floatingPassword">Please tell us why you need subscription cancel</label>
                                                {passworderr ? <p style={{ color: 'red', fontSize: "14px" }}>Description is required</p> : ""}
                                            </div>

                                            <div>
                                                <div className="btn btn-primary" onClick={Submit} >Send email</div>
                                                &nbsp;&nbsp;&nbsp;
                                                <div className="btn btn-primary" >Cancel</div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <ToastContainer
                        position="top-center"
                        autoClose={2100}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme={'dark'}
                    />
        </div> : <Redirect to='/' />
    )
}

export default UnSubscribe