import React, { useState, useEffect } from 'react'
import { NavLink, Redirect,useHistory } from 'react-router-dom'
import axios from 'axios'
import Loading from '../../loading/Loading'
import ReactPaginate from 'react-paginate'
import '../../css/pagination.css'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Editreport from './Addreport'
let complated = 'Completed';
export default function Reportlisting() {
    let userRegid = localStorage.getItem('user')
    let token = localStorage.getItem('token')
    let isAuthenticated = localStorage.getItem('loggedin')
    const history = useHistory();
    //set for pagination
    const [length, setLength] = useState(null)
    const [List, setList] = useState([])
    const [showloading, setLoading] = useState(true)
    let [pagenumber, setPagenumber] = useState(0)
    const [completedcount, setcompletedreport] = useState()
    const [incompletedcount, setincompletedreport] = useState()
    const [archivedcount, setarchivedreport] = useState()
    const pagesize = 5
    let pageCount = Math.ceil(length / pagesize)
    const changePage = ({ selected }) => {
        setPagenumber(selected)
        pagenumber = selected
        getReports(pagenumber, complated)
        setLoading(true)
    }


    //get-questions
    const getReports = (pagenumber, complated) => {
        axios.post(`${process.env.REACT_APP_API_URI}/user-report/report-list`, { pagenumber, pagesize, complated, userRegid: userRegid, token: token }).then((res) => {
            if (res.data.statuscode == 200) {
                setList(res.data.data.data)
                setLength(res.data.data.length)
                setLoading(false)
            }
        })
    }
    const getcounts = () => {
        axios.post(`${process.env.REACT_APP_API_URI}/user-report/count`, { userRegid: userRegid }).then((res) => {
            if (res.data.statuscode == 200) {
                setcompletedreport(res.data.data.completed)
                setincompletedreport(res.data.data.incompleted)
                setarchivedreport(res.data.data.archived)
            }
        })
    }
    useEffect(() => {
        getcounts()
        getReports(0, complated)
        setPagenumber(0)
    }, [])

    const getincomplate = () => {
        setLoading(true)
        getReports(0, complated = 'In-Progress')
        setPagenumber(0)
    }
    const getcomplate = () => {
        setLoading(true)
        getReports(0, complated = 'Completed')
        setPagenumber(0)
    }
    const getarchived = () => {
        setLoading(true)
        getReports(0, complated = 'Archived')
        setPagenumber(0)
    }
    const deletereport = (e, id,fullname) => {
        confirmAlert({
            title: 'Are you sure?',
            message: 'you want to delete this report ?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    var res = axios.post(`${process.env.REACT_APP_API_URI}/user-report/delete-user-report`, { userid: userRegid, _id: id ,token}).then((res) => {
                        console.log("res",res)
                        if (res.data.statuscode == 200) {
                              getReports(pagenumber,complated)
                              getcounts()
                        }
                    })
                }
              },
              {
                label: 'Cancel',
              }
            ]
          });
        

    }

    const editReport = (e, id,inProgress) => {
        console.log(inProgress)
        if(inProgress){
            history.push({
                pathname: "/editreport", state: { reportid: id }
            });
        }else{

            confirmAlert({
                title: 'Want to update report?',
                message: 'Only in progress reports are editable.This report will be moved to "In progress". are you sure?',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            var res = axios.post(`${process.env.REACT_APP_API_URI}/user-report/update-user-report-state`, { userid: userRegid, _id: id ,token,isinprogress:true,iscompleted:false,isarchive:false}).then((res) => {
                                console.log("res",res)
                                if (res.data.statuscode == 200) {
                                      getReports(pagenumber,complated)
                                      getcounts()
                                }
                            }) 
                        }
                    },
                    {
                        label: 'Cancel',
                    }
                ]
            });
        }
            

    }
    const addArchive = (e, id,isarchive) => {
        console.log(isarchive)
        if(isarchive){
            confirmAlert({
                title: 'Want to unarchive report?',
                message: 'This report will be moved to "In progress". are you sure?',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            setLoading(true)
                            var res = axios.post(`${process.env.REACT_APP_API_URI}/user-report/update-user-report-state`, { userid: userRegid, _id: id ,token,isinprogress:true,iscompleted:false,isarchive:false}).then((res) => {
                                console.log("res",res)
                                if (res.data.statuscode == 200) {
                                    getReports(pagenumber,complated)
                                    getcounts()
                                }
                                setLoading(false)
                            }) 
                        }
                    },
                    {
                        label: 'Cancel',
                    }
                ]
            });
        }else{

            confirmAlert({
                title: 'Want to archive report?',
                message: 'This report will be moved to "Archive". are you sure?',
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            setLoading(true)
                            var res = axios.post(`${process.env.REACT_APP_API_URI}/user-report/update-user-report-state`, { userid: userRegid, _id: id ,token,isinprogress:false,iscompleted:false,isarchive:true}).then((res) => {
                                console.log("res",res)
                                if (res.data.statuscode == 200) {
                                    getReports(pagenumber,complated)
                                    getcounts()
                                }
                                setLoading(false)
                            }) 
                        }
                    },
                    {
                        label: 'Cancel',
                    }
                ]
            });
        }
            

    }

    //table body
    const table = <tbody>
        {List && List.map((ele, i) => (
            <tr key={i}>
                <td>{pagenumber*5+i+1}</td>
                <td>{ele.fullname ? ele.fullname : "-"}</td>
                <td>{ele.address ? ele.address : "-"}</td>
                <td>{ele.lastupdateddate ? `Updated on ${new Date(ele.lastupdateddate).toLocaleDateString()}` : `Created on ${new Date(ele.createddate).toLocaleDateString()}`}</td>

                <td>
                    <NavLink to={{ pathname: "/viewreport", state: { reportid: ele._id } }} className="btn btn-primary btn-sm" >View</NavLink>
                </td>
                <td>
                    <button onClick={(e)=>editReport(e,ele._id,ele.isinprogress)} className="btn btn-primary btn-sm" >Edit</button>
                    {/* <NavLink to={{ pathname: "/editreport", state: { reportid: ele._id } }} className="btn btn-primary btn-sm" >Edit</NavLink> */}
                </td>
                <td>
                    <button className="btn btn-danger btn-sm" onClick={(e) => deletereport(e, ele._id,ele.fullname)}> Delete </button>
                </td>
                <td>
                    <button className="btn btn-primary btn-sm" onClick={(e) => addArchive(e, ele._id,ele.isarchive)}> {!ele.isarchive?'Archive':'UnArchive'} </button>
                </td>
            </tr>
        ))}
    </tbody>

    return (isAuthenticated ?
        <div className="container  m-0 p-0" style={{maxWidth:'none'}}>
            <div className="col-lg-18 grid-margin stretch-card ">
                <div className="card">
                    <div className="card-body" >
                        <h4 className="card-title">Report Listing({complated})</h4>
                        <div className="card-description">
                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li className="nav-item mx-2" role="presentation">
                                    <button className="btn btn-primary btn-rounded btn-fw" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-complate" aria-selected="true" onClick={() => getcomplate()}>Completed ({completedcount})</button>
                                </li>
                                <li className="nav-item mx-2" role="presentation">
                                    <button className="btn btn-primary btn-rounded btn-fw" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-incomplate" aria-selected="false" onClick={() => getincomplate()}>In-Progress ({incompletedcount})</button>
                                </li>
                                <li className="nav-item mx-2" role="presentation">
                                    <button className="btn btn-primary btn-rounded btn-fw" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-incomplate" aria-selected="false" onClick={() => getarchived()}>Archived ({archivedcount})</button>
                                </li>

                                <NavLink to={{ pathname: "/addreport", state: { registrationId: userRegid } }} className="btn btn-primary btn-sm" style={{ marginLeft: "auto", marginRight: "2%" }} >Add Report</NavLink>
                            </ul>
                            {showloading ? <div className="container  m-0 p-0">
                                <Loading /></div> :
                                List.length > 0 ? <div className='table-responsive' style={{ overflowX: "inherit" }}>
                                    <div className="tab-content" id="pills-tabContent">
                                        <table className='table table-striped h-100'>

                                            <thead>
                                                <tr>
                                                    <th> No</th>
                                                    <th> Fullname </th>
                                                    <th> Property address</th>
                                                    <th> Date </th>
                                                    <th> View</th>
                                                    <th> Edit</th>
                                                    <th> Delete</th>
                                                    <th> Archive/UnArchive</th>
                                                </tr>
                                            </thead>

                                            {table}
                                        </table>
                                    </div>
                                </div> : <div className='text-center w-100'><h2 className='mt-5'>Records not found</h2></div>}
                        </div>
                    </div>

                </div>
                <div className='m-auto mt-3'>
                    {List.length > 0 ? <ReactPaginate
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationbtns"}
                        previousLinkClassName={"prevBtn"}
                        nextLinkClassName={"nextBtn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                        forcePage={pagenumber}
                    /> : ""}
                </div>
            </div>



        </div> : <Redirect to='/' />
    )
}
